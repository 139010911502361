




















































































































import { Component, Vue, Ref } from 'vue-property-decorator'
import { getPlayListDetails, pageRingsByPlaylistId } from '@/api'
import { getPlatform } from '@/utils'
import PopUp from '@/components/PopUp.vue'
import Unfold from '@/components/Unfold.vue'
@Component({
  name: 'SonglistShare',
  components: {
    PopUp,
    Unfold
  }
})
export default class SonglistShare extends Vue {
  @Ref('audio')
  audioRef!: HTMLAudioElement

  pla = getPlatform()

  // 列表数据
  list: any = []

  // 是否处在加载状态
  loading = false

  // 是否已加载完成
  finished = false

  // 是否加载失败
  error = false

  // 分页
  pageNum = 1

  // 每页条数
  pageSize = 10

  // 数据总条数
  total = 0

  // 歌单id
  id = ''

  // 试看弹窗
  isSee = false

  // 试看结束弹窗
  isFinish = false

  // 设置弹窗
  isSet = false

  // 是否显示引导
  isGuidance = false

  // index值
  Index = -1

  /**歌单详情 */
  form = {
    /**歌单名称 */
    name: '',

    // 汉语标签类型
    categoryName: '',

    /**维语标签类型 */
    categoryUighurName: '',

    /**昵称 */
    userNickName: '',

    /**简介 */
    introduction: '',

    /**歌单封面 */
    coverUrl: ''
  }

  audioElement: any = []

  created() {
    // 获取歌单详情
    this.getPlayListDetails()

    this.audioElement = document.getElementsByTagName('audio')

    // 获取当前语言
  }

  get language() {
    return this.$i18n.locale
  }

  // 获取歌单详情
  async getPlayListDetails() {
    const { data } = await getPlayListDetails({
      id: this.$route.query.songlistId
    })
    this.form = { ...data }
  }

  // 设置
  handelSet() {
    this.isSet = true
  }

  // 试看事件
  handelSee() {
    this.isSee = false
    this.handlePlay(this.Index)
  }

  // 获取index值
  handelValue(index: number) {
    this.isSee = true
    this.Index = index
  }

  // 暂不下载事件
  handelnoDown() {
    this.isFinish = false
    this.isSet = false
  }

  // 下载事件
  handleDown() {
    this.isSee = false
    this.isFinish = false
    this.isSet = false
    if (this.pla === 'weixin' || this.pla === 'qq') {
      this.isGuidance = true
    } else {
      const loadDateTime = new Date().getTime()
      // 如果用户打开App失败，直接跳转下载
      const timer = setTimeout(() => {
        const timeOutDateTime = new Date().getTime()
        if (timeOutDateTime - loadDateTime < 3000) {
          window.location.href = process.env.VUE_APP_ALLO
        } else {
          window.close()
        }
      }, 1500)

      // 离开页面清除定时器
      window.onbeforeunload = function() {
        return clearTimeout(timer)
      }
      // 如果用户已经安装App直接跳转App
      window.location.href = 'allo://tel.top/home/callshow?select=2'
      this.isGuidance = false
    }
  }

  /*  播放歌曲 */
  handlePlay(index: any) {
    const audioElement = this.audioElement
    if (audioElement && audioElement.length > 0) {
      for (let i = 0; i < audioElement.length; i++) {
        if (i === index) {
          this.audioElement[i].play()
          setTimeout(() => {
            this.audioElement[i].pause()
            this.isFinish = true
          }, 10000)
        } else {
          this.audioElement[i].pause()
        }
      }
    }
  }

  // 获取列表数据
  async getpageRingsByPlaylist() {
    const { data } = await pageRingsByPlaylistId({
      pageNum: this.pageNum,
      pageSize: this.pageSize,
      id: this.$route.query.songlistId
    })
    if (data.list.length == 0 || data.list == null) {
      // 判断获取数据条数若等于0
      this.list = [] // 清空数组
      this.finished = true // 停止加载
    }
    // 若数据条数不等于0
    this.total = data.total // 给数据条数赋值
    this.list.push(...data.list) // 将数据放入list中
    this.loading = false // 加载状态结束
    // 如果list长度大于等于总数据条数,数据全部加载完成
    if (this.list.length >= data.total) {
      this.finished = true // 结束加载状态
    }
  }

  // 被 @load调用的方法
  onLoad() {
    this.getpageRingsByPlaylist() // 调用上面方法,请求数据
    this.pageNum++ // 分页数加一
    this.finished = false
  }
  // 加载失败调用方法
  onRefresh() {
    this.finished = false // 清空列表数据
    this.loading = true // 将 loading 设置为 true，表示处于加载状态
    this.pageNum = 1 // 分页数赋值为1
    this.list = [] // 清空数组
    this.onLoad() // 重新加载数据
  }
}
