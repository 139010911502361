


























import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  name: 'Unfold'
})
export default class Unfold extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  tip!: string

  // 数据
  @Prop({
    type: String,
    default: ''
  })
  data!: string

  // 最大长度
  @Prop({
    type: Number,
    default: 80
  })
  maxLen!: number

  showBtn = true

  unfoldIcon = require('@/assets/icon-unfold.png')

  packupIcon = require('@/assets/icon-combo.png')

  get sliceStr() {
    if (this.data !== null) {
      return this.data.substring(0, this.maxLen) + '...'
    } else {
      return ''
    }
  }
}
