





































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'PopUp'
})
export default class PopUp extends Vue {
  // 是否显示弹窗
  @Prop({
    type: Boolean,
    default: false
  })
  isshow!: boolean
  // 当前语言
  @Prop({
    type: String,
    default: 'ug'
  })
  language!: string
  // 标题
  @Prop({
    type: String,
    default: '提示'
  })
  title!: string

  // 文案
  @Prop({
    type: String,
    default: '下载Allo app可观看更多视频'
  })
  content!: string

  // 左按钮
  @Prop({
    type: String,
    default: '试看'
  })
  lfbtn!: string

  // 右按钮
  @Prop({
    type: String,
    default: '下载'
  })
  lrbtn!: string

  // 弹窗类型(5:提示弹窗、6:温馨提示弹窗)
  @Prop({
    type: Number,
    default: 5
  })
  Popuptype!: number

  // 试看和暂不下载
  handelSeeandNoDown() {
    if (this.Popuptype === 5) {
      // 试看
      this.$emit('handelSee')
    } else if (this.Popuptype === 6) {
      //暂不下载
      this.$emit('handelnoDown')
    } else {
      return false
    }
  }

  // 下载
  handelDownload() {
    // 除非父组件的方法
    this.$emit('handleDown')
  }
}
