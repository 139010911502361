import { render, staticRenderFns } from "./SonglistShare.vue?vue&type=template&id=4fdf03ce&scoped=true&"
import script from "./SonglistShare.vue?vue&type=script&lang=ts&"
export * from "./SonglistShare.vue?vue&type=script&lang=ts&"
import style0 from "./SonglistShare.vue?vue&type=style&index=0&id=4fdf03ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fdf03ce",
  null
  
)

export default component.exports